import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import Verse from "../../components/Verse.js";

export default function SweetPea({ data }) {
  return (
    <Layout>
      <Helmet title="Sweet Pea | Amos Lee" />

      <h1>Sweet Pea</h1>

      <h2>Amos Lee</h2>

      <h4>Key: E</h4>

      <h4>Tempo: 116</h4>

      <p>Non-ASCAP</p>

      <Link to="/">List</Link>

      <Columns columns="3">
        <Verse>
          <p>
            <Ch>E</Ch>Sweet Pea
          </p>
          <p>
            <Ch>G#7</Ch>Apple of my eye
          </p>
          <p>
            <Ch>C#m</Ch>I don't know when
          </p>
          <p>
            And I <Ch>F#7</Ch>don't know why
          </p>
        </Verse>
        <Chorus>
          <p>
            <Ch>E</Ch>You're the only <Ch>C#m</Ch>reason
          </p>
          <p>
            <Ch>A</Ch>I keep on
          </p>
          <p>
            <Ch>B</Ch>Coming home
          </p>
          <p>
            <b>E C#m A B</b>
          </p>
        </Chorus>
        <Verse>
          <p>
            <Ch>E</Ch>Sweet Pea
          </p>
          <p>
            What's all of <Ch>G#7</Ch>this about
          </p>
          <p>
            <Ch>C#m</Ch>Don't get your way
          </p>
          <p>
            All you <Ch>F#7</Ch>do is fuss and pout
          </p>
        </Verse>
        <Chorus>
          <p>
            <Ch>E</Ch>You're the only <Ch>C#m</Ch>reason
          </p>
          <p>
            <Ch>A</Ch>I keep on
          </p>
          <p>
            <Ch>B</Ch>Coming home
          </p>
          <p>
            <b>E C#m A B</b>
          </p>
        </Chorus>
        <Bridge>
          <p>
            I'm like the <Ch>G#7</Ch>rock of Gibralter
          </p>
          <p>I always seem to falter</p>
          <p>
            And the <Ch>C#m</Ch>words just get in the way
          </p>
          <p>
            I <Ch>F#7</Ch>know I'm going to crumble
          </p>
          <p>I'm just trying to stay humble</p>
          <p>
            But I <Ch>B</Ch>never think before I say
          </p>
        </Bridge>
        <Chorus>
          <p>
            <b>E G#7 C#m F#7</b>
          </p>
          <p>
            <b>E C#m A B</b>
          </p>
          <p>
            <b>E C#m A B</b>
          </p>
        </Chorus>
        <Verse>
          <p>
            <Ch>E</Ch>Sweet Pea
          </p>
          <p>
            <Ch>G#7</Ch>Keeper of my soul
          </p>
          <p>
            <Ch>C#m</Ch>I know sometimes
          </p>
          <p>
            <Ch>F#7</Ch>I'm out of control
          </p>
        </Verse>
        <Chorus>
          <p>
            <Ch>E</Ch>You're the only <Ch>C#m</Ch>reason
          </p>
          <p>
            <Ch>A</Ch>I keep on
          </p>
          <p>
            <Ch>B</Ch>Coming
          </p>
        </Chorus>
        <Chorus>
          <p>
            <Ch>E</Ch>You're the only <Ch>C#m</Ch>reason
          </p>
          <p>
            <Ch>A</Ch>I keep on
          </p>
          <p>
            <Ch>B</Ch>Coming, yeah
          </p>
        </Chorus>
        <Chorus>
          <p>
            <Ch>E</Ch>You're the only <Ch>C#m</Ch>reason
          </p>
          <p>
            <Ch>A</Ch>I keep on
          </p>
          <p>
            <Ch>B</Ch>Coming home
          </p>
          <p>
            <b>E A E A E</b>
          </p>
        </Chorus>
      </Columns>
    </Layout>
  );
}
